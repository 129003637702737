<template>
  <TemplatePopup
    :showMessage="saveDisabled"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
    <div slot="header" class="header-container">
      <div class="header-title">
        <strong>CTO</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>

    <div slot="body">
      <!-- <div class="float-right">
        <button
          data-balloon-length="large"
          aria-label="Conheça a funcionalidade: Área de atuação"
          data-balloon-pos="left"
          class="nav-item nav-link button-disabled"
          @click.prevent="help('https://www.youtube.com/embed/mFnHWVZcBsU')"
        >
          <span class="icon is-small">
            <i class="fa fa-video-camera" aria-hidden="true"></i>
          </span>
        </button>
      </div> -->
      <tabs>
        <tab title="Dados" :selected="true">
          <b-form-group label="Nome (TAG):">
            <b-form-input
              id="inputName"
              v-model="entity.name"
              :class="{ 'is-invalid': missingName }"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Modelo:">
            <b-form-select
              id="modelList"
              v-model="entity.ctoModel.id"
              :class="{ 'is-invalid': missingModel }"
              size="sm"
            >
              <b-form-select-option
                v-for="entityList in modelList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Endereço:">
            <b-form-input
              id="inputAddress"
              v-model="entity.address"
              maxlength="255"
              :class="{ 'is-invalid': missingAddress }"
              :disabled="entity.category === 'I'"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Endereços sugeridos:"
            v-if="isNew && entity.category != 'I'"
          >
            <b-form-select id="addressList" v-model="entity.address" size="sm">
              <b-form-select-option
                v-for="(address, index) in addressList"
                :value="address.formatted_address"
                :key="index"
              >
                {{ address.formatted_address }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Cabos sangria:" v-if="cableList.length">
            <b-form-select
              id="cableList"
              v-model="entity.cable"
              :disabled="!isNew"
              size="sm"
              title="Para fazer a sangria ou quebrar um cabo selecione na lista, caso não deseje quebrar o cabo deixe o campo em vazio"
            >
              >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(cable, index) in cableList"
                :value="cable"
                :key="index"
              >
                {{ cable.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select id="folderList" v-model="entity.folderId" size="sm">
              <b-form-select-option
                v-for="entityList in folderList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Cor:">
            <b-form-select id="colorList" v-model="entity.color" size="sm">
              <b-form-select-option value="lightblue">Azul claro</b-form-select-option>
              <b-form-select-option value="blue">Azul escuro</b-form-select-option>
              <b-form-select-option value="lightgray">Cinza claro</b-form-select-option> 
              <b-form-select-option value="gray">Cinza escuro</b-form-select-option>
              <b-form-select-option value="yellow">Amarela</b-form-select-option> 
              <b-form-select-option value="orange">Laranja</b-form-select-option> 
              <b-form-select-option value="green">Verde</b-form-select-option> 
              <b-form-select-option value="red">Vermelha</b-form-select-option> 
              <b-form-select-option value="brown">Marrom</b-form-select-option> 
              <b-form-select-option value="pink">Rosa claro</b-form-select-option> 
              <b-form-select-option value="darkpink">Rosa escuro</b-form-select-option> 
            </b-form-select>
          </b-form-group>

          <b-form-group label="Bloco:" v-if="entity.category === 'I'">
            <b-form-input
              id="inputBlock"
              v-model="entity.block"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Andar:" v-if="entity.category === 'I'">
            <b-form-input
              type="number"
              id="inputFloor"
              v-model="entity.floor"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-row>
            <b-col cols="8">
              <b-form-group label="CTO Mapeada:">
                <b-form-select
                  id="mappedCtoList"
                  v-model="entity.mappedCto"
                  size="sm"
                >
                  <b-form-select-option value="S"> Sim </b-form-select-option>
                  <b-form-select-option value="N"> Não </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Data do Mapeamento:"
                v-if="entity.mappedCto == 'S'"
              >
                <b-form-datepicker
                  id="mappingDate"
                  v-model="entity.mappingDate"
                  size="sm"
                  placeholder=""
                  :min="entity.startDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                >
                </b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <div class="row mt-2" v-if="!isNew">
            <div class="col-md-6 col-sm-12 mb-1">
              <CardUsage
                id="usage"
                text="Taxa de utilização:"
                :value="entity.usage"
              />
            </div>
            <div class="col-md-6 col-sm-12 mb-1">
              <Card
                id="avaliableEquipments"
                text="Portas disponíveis:"
                :value="entity.avaliableEquipments"
              />
            </div>
          </div>
        </tab>
        <tab
          id="tabCables"
          title="Cabos"
          v-if="!isNew"
          :permission="['CAB', 'VI']"
        >
          <Cables :equipmentId="entity.id" />
        </tab>
        <tab
          id="tabSplitter"
          title="Splitter"
          :permission="['SPL', 'VI']"
          v-if="!isNew"
        >
          <Splitter
            :equipmentId="entity.id"
            :updateAction="getOne"
            :showAttendance="true"
          />
        </tab>
        <tab
          id="connectedClients"
          title="Clientes Conectados"
          :permission="['SPL', 'VI']"
          v-if="!isNew"
        >
          <ConnectedClients
            :equipmentId="entity.id"
            :updateAction="getOne"
            :showAttendance="true"
          />
        </tab>
      </tabs>
    </div>
    <div slot="button">
  <div class="text-center">
    <b-button-group class="my-2">
      <b-button
        id="btnOtdr"
        :disabled="isNew"
        @click.prevent="OTDR()"
        v-has-permission.permission="['OTR', 'VI']"
        variant="muted"
      >
        <i class="fa fa-map-marker fa-lg" aria-hidden="true"></i>
        <p class="button-text">OTDR</p>
      </b-button>
      <b-button
        id="btnActing"
        :disabled="isNew"
        @click.prevent="drawnLimit()"
        v-has-permission.permission="['DRA', 'VI']"
        variant="muted"
      >
        <i class="fa fa-object-group fa-lg" aria-hidden="true"></i>
        <p class="button-text">Atuação</p>
      </b-button>

      <b-button
        id="btnReport"
        :disabled="isNew"
        @click.prevent="connectionReport()"
        v-has-permission.permission="['REL', 'VI']"
        variant="muted"
      >
        <i class="fa fa-bar-chart fa-lg" aria-hidden="true"></i>
        <p class="button-text">Relatório</p>
      </b-button>

      <b-button
        id="btnComments"
        :disabled="isNew"
        @click.prevent="comment()"
        v-has-permission.permission="['COM', 'VI']"
        variant="muted"
      >
        <i class="fa fa-comments fa-lg" aria-hidden="true"></i>
        <p class="button-text">Comentários</p>
      </b-button>

      <b-button
        id="btnImage"
        :disabled="isNew"
        @click.prevent="image()"
        v-has-permission.permission="['IMG', 'VI']"
        variant="muted"
      >
        <i class="fa fa-picture-o fa-lg" aria-hidden="true"></i>
        <p class="button-text">Imagem</p>
      </b-button>

      <b-button
        id="btnConnections"
        :disabled="isNew"
        @click.prevent="conexao()"
        v-has-permission.permission="['CON', 'VI']"
        variant="muted"
      >
        <i class="fa fa-sitemap fa-lg" aria-hidden="true"></i>
        <p class="button-text">Conexões</p>
      </b-button>

      <b-button
        id="btnDelete"
        @click.prevent="removeCto()"
        v-has-permission.permission="['CTO', 'EX']"
        variant="muted"
        :disabled="isNew"
      >
        <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
        <p class="button-text">Excluir</p>
      </b-button>

      <b-button
        id="btnSave"
        @click.prevent="save()"
        v-has-permission.permission="['CTO', 'AL', 'CA']"
        variant="muted"
        :disabled="saveDisabled"
      >
        <i class="fa fa-floppy-o fa-lg"></i>
        <p class="button-text">Salvar</p>
      </b-button>
    </b-button-group>
  </div>
</div>

  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import Splitter from "./Tabs/Splitter";
import Cables from "./Tabs/Cables";
import ConnectedClients from "./Tabs/ConnectedClients.vue";
import { sendMapAction, refreshLimit } from "../../../components/MessageBus";
import CreationDate from "./CreationDate"

import {
  ctoPath,
  ctoModelSimplePath,
  folderSimplePath
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import Usage from "../../../components/extra/Usage";
import Card from "../../../components/extra/Card";
import CardUsage from "../../../components/extra/CardUsage";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import { openSubPopup } from "./index";

export default {
  name: "Cto",
  extends: PopupBase,
  data() {
    return {
      modelList: [],
      folderList: []
    };
  },
  components: {
    TemplatePopup,
    Splitter,
    Cables,
    ConnectedClients,
    Usage,
    Card,
    CardUsage,
    Tabs,
    Tab,
    CreationDate
  },
  beforeMount() {
    this.entity = {
      id: null,
      cable: null,
      ctoModel: {},
      mappingDate: {},
      mappedCto: "N",
      splitterModel: {},
      color: "gray",
      category: this.extra && this.extra.category ? this.extra.category : "E",
      address:
        this.extra && this.extra.address
          ? this.extra.address
          : this.entity.address,
      equipmentExternalId:
        this.extra && this.extra.equipmentExternalId
          ? this.extra.equipmentExternalId
          : undefined,
      positionDiagram:
        this.extra && this.extra.positionDiagram
          ? this.extra.positionDiagram
          : undefined,  
    };
  },
  mounted() {
    let path = ctoPath(this.folderId);

    this.getAllDirect(ctoModelSimplePath).then(data => {
      this.modelList = data;
    });

    this.getAllDirect(folderSimplePath).then(data => {
      this.folderList = data;
    });

    if (this.extra && this.extra.category === "I") this.init(path);
    else this.init(path, true, this.isNew);

    // let date = new Date()
    // let newDate = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDay()
    let today = new Date().toISOString().substr(0, 10);
    this.entity.mappingDate = today;
  },
  computed: {
    missingModel: function() {
      // console.log(this.entity.ctoModel);
      return !this.entity.ctoModel || !this.entity.ctoModel.id;
    },
    missingName: function() {
      return !this.entity.name;
    },
    missingAddress: function() {
      return !this.entity.address;
    },
    saveDisabled: function() {
      return this.missingName || this.missingModel || this.missingAddress || !this.isDirty;
    },
    bgc() {
      this.colors.hex;
      return this.colors.hex;
    }
  },
  methods: {
    removeCto() {
      this.remove();
      sendMapAction(refreshLimit);
    },
    help(url) {
      openSubPopup(32, null, null, null, null, url);
    },
    showPicker() {
      this.displayPicker = true;
    },
    hidePicker() {
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateValue(value) {
      this.colors = value;
      this.entity.color = value.hex;
    }
  }
};
</script>

<style scoped>
.vc-sketch {
  position: fixed;
  top: 25px;
  right: 20px;
  z-index: auto;
}
.current-color {
  /* display: inline-block; */
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
}

.button-color {
  border: 1px solid #ced4da;
  background: transparent;
  color: #495057;
}

.button-color:focus {
  outline: none;
  box-shadow: none;
}
</style>
